import React, { useState } from 'react';
import slidesData from './slides'; // Importera slides
import styles from './InfoWindow.module.css'; // Importera CSS Module

const InfoWindow = ({ page }) => {
    // Om vi är på game-sidan: öppna alltid infofönstret initialt.
    // Annars kollar vi localStorage om infofönstret tidigare stängts.
    const initialOpen = page === 'game' 
        ? true 
        : (!localStorage.getItem('infoWindowClosed') ? true : false);
    const [isOpen, setIsOpen] = useState(initialOpen);
    const [currentSlide, setCurrentSlide] = useState(0);
    const circleBottom = 20; // Position från botten

    // Hämta slides för den aktuella sidan
    const slides = slidesData[page] || [];

    // Funktion för att stänga infofönstret.
    // För sidor utanför game sparas stängningsstatus i localStorage.
    const closeWindow = () => {
        setCurrentSlide(0);
        setIsOpen(false);
        if (page !== 'game') {
            localStorage.setItem('infoWindowClosed', 'true');
        }
    };

    const handleOverlayClick = () => {
        closeWindow();
    };

    const handleNextSlide = () => {
        setCurrentSlide((prev) => {
            if (prev + 1 === slides.length) {
                closeWindow();
                return prev;
            }
            return prev + 1;
        });
    };

    const handlePreviousSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };

    // Toggle-funktion: Om infofönstret är stängt öppnar vi det.
    // Om det är öppet stänger vi det och sparar flaggan (för sidor utanför game).
    const toggleWindow = () => {
        if (!isOpen) {
            setIsOpen(true);
            if (page !== 'game') {
                localStorage.removeItem('infoWindowClosed');
            }
        } else {
            closeWindow();
        }
    };

    return (
        <>
            <div className="inforcircle">
                <div
                    className={styles.infoCircle}
                    onClick={toggleWindow}
                    title="Visa information"
                    style={{ bottom: `${circleBottom}px` }}
                >
                    I
                </div>
            </div>
            {isOpen && (
                <div
                    className={styles.overlay}
                    onClick={handleOverlayClick} // Stäng infofönstret vid klick utanför
                >
                    <div
                        className={styles.infoWindow}
                        onClick={(e) => e.stopPropagation()} // Förhindra att klick inuti fönstret stänger det
                    >
                        <div className={styles.infoHeader}>
                            <h2> </h2>
                            <button
                                className={styles.closeButton}
                                onClick={closeWindow}
                            >
                                ×
                            </button>
                        </div>
                        <div className={styles.infoContent}>
                            <div className={styles.emoji}>
                                {slides[currentSlide].split(' ')[0]}
                            </div>
                            <p className={styles.text}>
                                {slides[currentSlide].substring(
                                    slides[currentSlide].indexOf(' ') + 1
                                )}
                            </p>
                        </div>
                        <div className={styles.dots}>
                            {slides.map((_, index) => (
                                <span
                                    key={index}
                                    className={`${styles.dot} ${
                                        index === currentSlide ? styles.activeDot : ''
                                    }`}
                                />
                            ))}
                        </div>
                        <div className={styles.infoNavigation}>
                            <button
                                onClick={handlePreviousSlide}
                                className={styles.prevButton}
                            >
                                Föregående
                            </button>
                            <button
                                onClick={handleNextSlide}
                                className={styles.nextButton}
                            >
                                Nästa
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default InfoWindow;
