import React, { useState, useEffect, useRef } from 'react';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail
} from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import './LoginRegister.css';

function LoginRegister({ onClose, onUsernameChange, setIsRegisteringVisible }) {
  const [message, setMessage] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [savedEmail, setSavedEmail] = useState('');
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const auth = getAuth();
  const db = getFirestore();
  const modalRef = useRef(null);

  const defaultProfileImageUrl = 'https://firebasestorage.googleapis.com/v0/b/misiona-d1330.appspot.com/o/defaultprofile.png?alt=media&token=abdfae4a-a9ab-4f0f-bc63-b278c0a553d4';

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setSavedEmail(storedEmail);
    }
    onAuthStateChanged(auth, user => {
      if (user) {
        onClose();
      }
    });
  }, [auth, onClose]);

  const toggleRegistering = () => {
    setIsRegistering(prev => !prev);
    setUsername('');
    if (!isRegistering) {
      if (typeof onUsernameChange === 'function') {
        onUsernameChange('');
      }
      if (typeof setIsRegisteringVisible === 'function') {
        setIsRegisteringVisible(true);
      }
    } else {
      if (typeof setIsRegisteringVisible === 'function') {
        setIsRegisteringVisible(false);
      }
    }
  };

  const handleLogin = e => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        localStorage.setItem('email', email);
        onClose();
      })
      .catch(error => {
        if (error.code === 'auth/user-not-found') {
          setMessage('E-postadressen är inte registrerad.');
        } else if (error.code === 'auth/wrong-password') {
          setMessage('Fel lösenord.');
        } else {
          setMessage('Ett fel uppstod vid inloggning.');
        }
      });
  };

  const handleRegister = async e => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then(async userCredential => {
        await updateProfile(userCredential.user, {
          displayName: username,
          photoURL: defaultProfileImageUrl
        });
        const userRef = doc(db, 'users', userCredential.user.uid);
        await setDoc(userRef, {
          points: 10,
          username: username,
          profileImageUrl: defaultProfileImageUrl
        });
        localStorage.setItem('email', email);
        localStorage.setItem('username', username);
        localStorage.setItem('profileImageUrl', defaultProfileImageUrl);
        setTimeout(() => window.location.reload(), 500);
        onClose();
      })
      .catch(error => {
        if (error.code === 'auth/email-already-in-use') {
          setMessage('Ett konto med denna e-postadress finns redan.');
        } else if (error.code === 'auth/weak-password') {
          setMessage('Lösenordet är för svagt. Välj ett starkare lösenord.');
        } else {
          setMessage('Ett fel uppstod vid registrering.');
        }
      });
  };

  useEffect(() => {
    if (isRegistering && typeof onUsernameChange === 'function') {
      onUsernameChange(username);
    }
  }, [username, onUsernameChange, isRegistering]);

  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async result => {
        const user = result.user;
        const userRef = doc(db, 'users', user.uid);
        const userSnapshot = await getDoc(userRef);
        if (!userSnapshot.exists()) {
          await setDoc(userRef, {
            points: 10,
            username: user.displayName,
            profileImageUrl: user.photoURL || defaultProfileImageUrl
          });
        }
        onClose();
      })
      .catch(error => {
        setMessage('Ett fel uppstod vid Google inloggning.');
        console.error('Google sign-in error: ', error);
      });
  };

  const handlePasswordReset = e => {
    e.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setMessage('Återställningsmail har skickats.');
      })
      .catch(error => {
        setMessage('Ett fel uppstod vid återställning av lösenord.');
        console.error('Password reset error: ', error);
      });
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="loginregiss">
      <div className="modal-overlay">
        <div className="signapp" ref={modalRef}>
          <div className="logreg">
            <form
              className="reg-form"
              onSubmit={
                isRegistering
                  ? handleRegister
                  : isResettingPassword
                  ? handlePasswordReset
                  : handleLogin
              }
            >
              <div className="login-title">LOGGA IN</div>
              <input
                type="email"
                className="placeinfo"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="E-mail"
                list="email-suggestions"
                tabIndex="1"
              />
              <datalist id="email-suggestions">
                <option value={savedEmail} />
              </datalist>
              {!isResettingPassword && (
                <input
                  type="password"
                  className="placeinfo"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  placeholder="Lösenord"
                  tabIndex="2"
                />
              )}
              {isRegistering && (
                <input
                  type="username"
                  className="placeinfo"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  placeholder="Användarnamn"
                  tabIndex="3"
                />
              )}
              <button className="login-button" type="submit" tabIndex="4">
                {isRegistering
                  ? 'Registrera'
                  : isResettingPassword
                  ? 'Skicka återställningsmail'
                  : 'Logga in'}
              </button>
              {isResettingPassword && (
                <button
                  className="tillbaka-button"
                  type="button"
                  onClick={() => setIsResettingPassword(false)}
                  tabIndex="5"
                >
                  Tillbaka
                </button>
              )}
              {!isResettingPassword && (
                <>
                  <button
                    className="login-button1"
                    type="button"
                    onClick={toggleRegistering}
                    tabIndex="6"
                  >
                    {isRegistering
                      ? 'Har redan ett konto? Logga in'
                      : 'Inget konto? Registrera dig'}
                  </button>
                  <button
                    type="button"
                    className="google-signin-button"
                    onClick={handleGoogleSignIn}
                    tabIndex="7"
                  >
                    Logga in med Google
                  </button>
                  <div
                    className="forgot-password-button"
                    onClick={() => setIsResettingPassword(true)}
                    tabIndex="8"
                  >
                    Har du glömt ditt lösenord?
                  </div>
                </>
              )}
              {message && <div className="message-login-register">{message}</div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

LoginRegister.defaultProps = {
  onUsernameChange: () => {},
  setIsRegisteringVisible: () => {}
};

export default LoginRegister;
